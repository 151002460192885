import { useEffect, useState } from "react";
import { useLabeling } from "@remhealth/core";
import { HealthcareService, type Reference } from "@remhealth/apollo";
import {
  Classes,
  Ellipsize,
  InputGroup,
  NonIdealState,
  PagingGrid,
  Tooltip,
  useDebouncedState,
  usePagingController
} from "@remhealth/ui";
import { PagedResults } from "@remhealth/mastodon";
import { WarningSign } from "@remhealth/icons";
import { Text } from "~/text";

import { Modal, SearchGroup } from "./serviceTypeDialog.styles";

export interface EhrServiceTypeDialogProps {
  isOpen: boolean;
  disabledReason?: (item: Reference<HealthcareService>) => string | undefined;
  onClose: () => void;
  onSelect: (healtcareService: HealthcareService) => void;
  onLoad: (query: string, limit: number, continuationToken: string | undefined, abort: AbortSignal) => Promise<PagedResults<HealthcareService>>;
}

export const EhrServiceTypeDialog = (props: EhrServiceTypeDialogProps) => {
  const { isOpen, disabledReason, onClose, onSelect, onLoad } = props;

  const labels = useLabeling();
  const controller = usePagingController();

  const [searchText, setSearchText] = useDebouncedState("", 300);
  const [continuationToken, setContinuationToken] = useState<string>();

  useEffect(() => {
    setContinuationToken(undefined);
    controller.reset();
  }, [searchText]);

  return (
    <Modal
      isOpen={isOpen}
      title={labels.ServiceTypes}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <SearchGroup>
          <InputGroup
            clearable
            fill
            large
            placeholder={`Search ${labels.serviceTypes}`}
            type="search"
            onChange={setSearchText}
          />
        </SearchGroup>
        <PagingGrid<HealthcareService>
          fixed
          interactive
          stickyHeader
          controller={controller}
          headerRenderer={headerRenderer}
          noResults={renderEmptyRecord}
          pageKey="ehr-service-types"
          rowRenderer={rowRenderer}
          onLoadMore={handleLoad}
          onPageSizeChanged={handlePageSizeChanged}
        />
      </div>
    </Modal>
  );

  function handlePageSizeChanged() {
    setContinuationToken(undefined);
  }

  async function handleLoad(_: number, limit: number, abort: AbortSignal) {
    const response = await onLoad(searchText, limit, continuationToken, abort);
    setContinuationToken(response.continuationToken);
    return { hasMore: !!response.continuationToken, items: response.results };
  }

  function renderEmptyRecord() {
    const content = searchText ? Text.NoSearchResults(labels.ServiceType) : `There are no ${labels.ServiceTypes.toLowerCase()} to display.`;
    return (
      <tr>
        <td colSpan={2}>
          <NonIdealState
            description={content}
          />
        </td>
      </tr>
    );
  }

  function headerRenderer() {
    return (
      <tr>
        <th>Service</th>
        <th>Code</th>
        <th />
      </tr>
    );
  }

  function rowRenderer(healthcareService: HealthcareService) {
    const reason = disabledReason?.(healthcareService);
    return (
      <tr key={healthcareService.id} className={reason ? Classes.TEXT_MUTED : undefined} onClick={() => !reason ? onSelect(healthcareService) : undefined}>
        <td><Ellipsize>{healthcareService.display}</Ellipsize></td>
        <td>{healthcareService.aliases[0]}</td>
        <td>
          {reason && <Tooltip content={reason}><WarningSign intent="warning" /></Tooltip>}
        </td>
      </tr>
    );
  }
};
