import { HealthcareService, LocalDate, LocationRole, type Reference } from "@remhealth/apollo";
import { Classes, InputGroup, useDebouncedState } from "@remhealth/ui";
import { useLabeling } from "@remhealth/core";
import { Text } from "~/text";

import { ServiceTypeGrid, ServiceTypeGridColumn } from "./serviceTypeGrid";
import { Modal, SearchGroup } from "./serviceTypeDialog.styles";

export interface ServiceTypeDialogProps {
  isOpen: boolean;
  locationRole?: LocationRole;
  visitDate?: LocalDate;
  missedVisitsOnly?: boolean;
  limitToServiceTypeIds?: string[];
  excludedServiceTypeIds?: string[];
  disabledReason?: (item: Reference<HealthcareService>) => string | undefined;
  onClose: () => void;
  onSelect: (healtcareService: HealthcareService) => void;
}

const columnList: ServiceTypeGridColumn[] = ["Service", "ServiceTypeCode", "NonBillable"];

export const ServiceTypeDialog = (props: ServiceTypeDialogProps) => {
  const {
    isOpen,
    locationRole,
    visitDate,
    limitToServiceTypeIds,
    excludedServiceTypeIds,
    missedVisitsOnly = false,
    disabledReason,
    onClose,
    onSelect,
  } = props;

  const labels = useLabeling();

  const [searchText, setSearchText] = useDebouncedState("", 300);

  return (
    <Modal isOpen={isOpen} title={labels.ServiceTypes} onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <SearchGroup>
          <InputGroup
            clearable
            fill
            large
            placeholder={Text.SearchServiceTypes(labels)}
            type="search"
            onChange={setSearchText}
          />
        </SearchGroup>
        <ServiceTypeGrid
          allowedLocations={locationRole ? [locationRole] : undefined}
          columns={columnList}
          disabledReason={disabledReason}
          effectiveDate={visitDate}
          excludedServiceTypeIds={excludedServiceTypeIds}
          limitToServiceTypeIds={limitToServiceTypeIds}
          missedVisitsOnly={missedVisitsOnly}
          pageKey="all-healthcareservices"
          query={searchText}
          onClick={onSelect}
        />
      </div>
    </Modal>
  );
};
