import { useContext } from "react";
import {
  createRef,
  useAnalyticIngester,
  useApollo,
  usePracticePreferences,
  useProductFlag,
  useTracking,
  useUserProfile,
  useUserSession
} from "@remhealth/host";
import {
  CarePlanOutcome,
  CarePlanSession,
  type Dialogue,
  Duration,
  type Encounter,
  Instant,
  type MultiNote,
  Note,
  NoteSectionFormat,
  PatientNote,
  Practitioner,
  type Reference,
  Signature,
  isMultiNote,
  isPatientNote
} from "@remhealth/apollo";
import { useAudit, useStore } from "@remhealth/core";
import { useSubscriptionRef } from "@remhealth/ui";
import { useComposeRegistry } from "@remhealth/compose";
import { parseRuleIdRef } from "@remhealth/unity";
import { NoteSuggestionsContext } from "~/contexts";
import { getDeadline, getInstantRangeDuration, getVisitStatus } from "~/utils";
import { NoteEditPromptsContext } from "~/notes/prompts/noteEditPromptsContext";
import { useNoteSummary } from "~/notes/summary/useNoteSummary";
import { computeUsageMetrics } from "~/compose";

export function useFinalizeNote() {
  const store = useStore();
  const apollo = useApollo();
  const audit = useAudit();
  const ingester = useAnalyticIngester();
  const user = useUserProfile();
  const session = useUserSession();
  const tracking = useTracking();
  const composeEditors = useComposeRegistry();
  const practicePreferences = usePracticePreferences();
  const { summarizeNote } = useNoteSummary();
  const allowCaptureSignature = useProductFlag("AllowCaptureSignature");

  const { subscription: suggestions } = useContext(NoteSuggestionsContext);
  const prompts = useSubscriptionRef(NoteEditPromptsContext);

  const practitioner = session.person as Practitioner;

  return {
    requestSignature,
    signNote,
    deleteNote,
    deleteEncounter,
    deleteDialogue,
    deleteCarePlanOutcome,
  };

  function requestSignature() {
    prompts.set(value => ({
      ...value,
      signing: true,
      signedOutcome: {
        linkAppointment: undefined,
        createAppointment: false,
        locationKind: undefined,
        assignedReviewers: false,
        reviewers: [],
      },
    }));
  }

  function handleSigned() {
    prompts.set(value => ({
      ...value,
      signing: false,
      signedOutcome: undefined,
    }));
  }

  async function signNote(note: Note): Promise<void> {
    // Ensure encounter data finished saving before allowing note to sign
    await store.encounters.waitForPendingWrites();

    const encounter = await store.encounters.expand(note.encounter);
    const encounterStatus = encounter.status === "NoShow" || encounter.status === "Cancelled"
      ? encounter.status
      : "Finished";

    const program = encounter.program ? await store.programs.expand(encounter.program) : undefined;
    const sessionToSignIncludesWeekends = program?.noteComplianceOverrides?.sessionToSignIncludesWeekends ?? practicePreferences.sessionToSignIncludesWeekends;
    const sessionToSignThreshold = program?.noteComplianceOverrides?.sessionToSignThresholdHours ?? practicePreferences.noteSignWarningTimeout;
    const thresholdDuration = Duration.fromHours(sessionToSignThreshold);
    const signDeadline = getDeadline(note.recorded, thresholdDuration, sessionToSignIncludesWeekends);

    const signed = Instant.now();
    const createdToSignMinutes = Math.round(Instant.toDateTime(signed).diff(Instant.toDateTime(note.created)).as("minutes"));
    const sessionToSignMinutes = Math.round(Duration.toLuxon(getInstantRangeDuration(note.recorded, signed, sessionToSignIncludesWeekends)).as("minutes"));
    const signLateness = getInstantRangeDuration(signDeadline, signed, sessionToSignIncludesWeekends);
    const sessionToSignLateMinutes = Math.round(Duration.toLuxon(signLateness).as("minutes"));
    const metrics = composeEditors.getAll().map(computeUsageMetrics);

    const createAppointment = shouldCreateAppointment(note, !!prompts.current.signedOutcome?.createAppointment);

    const signatureImage = allowCaptureSignature && practitioner.signature
      ? await store.media.expand(practitioner.signature)
      : undefined;

    const newSignature: Signature = {
      signed,
      signer: user,
      signatureImage: signatureImage ? {
        resourceType: "Media",
        partition: signatureImage.partition,
        id: signatureImage.id,
        versionId: signatureImage.meta?.versionId,
        display: signatureImage.display,
      } : undefined,
    };

    const signatures = note.signatures.some(s => s.signer?.id === user.id)
      ? note.signatures
      : [...note.signatures, newSignature];

    const extensions = (note.extensions ?? []).filter(s => s.name !== "userSystem");

    // Remember user's system for sync to utilize it
    if (session.system?.value) {
      extensions.push({ name: "userSystem", value: session.system.value });
    }

    encounter.documentationDeadline = signDeadline;
    encounter.documentationStatus = "Complete";
    encounter.status = encounterStatus;
    encounter.appointment = prompts.current.signedOutcome?.linkAppointment ?? encounter.appointment;

    if (createAppointment && prompts.current.signedOutcome?.locationKind) {
      encounter.location = { ...encounter.location!, kind: prompts.current.signedOutcome.locationKind };
    }

    // Transcriptions, Summaries and the Audio Files should be deleted as soon as the note tied to the session/transcription/summary/audio file is signed.
    await clearDialogues(encounter);
    encounter.dialogues = [];

    await store.encounters.upsertAsync(encounter);

    note = {
      ...note,
      status: "Final",
      signatures,
      signDeadline,
      signLateness,
      extensions,
      createAppointment,
      reviewers: prompts.current.signedOutcome?.assignedReviewers
        ? prompts.current.signedOutcome.reviewers
        : note.reviewers,
      meta: {
        ...note.meta ?? {},
        lastModified: Instant.now(),
      },
    };

    if (isPatientNote(note)) {
      await summarizeNote(note);
    }

    await store.notes.upsertAsync(note);

    const carePlanOutcome = note.derivedFrom ? await store.carePlanOutcomes.expand(note.derivedFrom) : null;
    if (carePlanOutcome && carePlanOutcome.status !== "Final") {
      carePlanOutcome.status = "Final";
      store.carePlanOutcomes.upsert(carePlanOutcome);
    }

    audit.log(isMultiNote(note) ? "group-note-signed" : "note-signed", {
      entities: [note, note.subject],
    });

    tracking.track("Note - Sign Note", {
      encounterId: encounter.id,
      noteId: note.id,
      createdToSign: createdToSignMinutes,
      sessionToSign: sessionToSignMinutes,
      appointmentCreated: createAppointment,
    });

    const primaryParticipant = note.participants.find(p => p.role === "PrimaryPerformer");

    if (note.subject.resourceType === "Patient" && primaryParticipant) {
      ingester.event("INoteSignedV4", {
        payload: {
          encounter: createRef(encounter),
          note: createRef(note),
          noteType: createRef(note.definition),
          patient: createRef(note.subject),
          primaryParticipant: createRef(primaryParticipant.individual),
          serviceType: createRef(encounter.serviceType),
          program: createRef(encounter.program),
          visitStatus: getVisitStatus(encounter.status),
          visitTime: Instant.toDate(note.recorded).toISOString(),
          serviceLocation: createRef(encounter.location?.location),
          locationRole: encounter.location?.role,
          locationKind: createRef(encounter.location?.kind),
          appointment: createRef(encounter.appointment),
          dropIns: metrics.reduce((sum, metric) => sum + metric.snippets.count, 0),
          expansions: metrics.reduce((sum, metric) => sum + metric.expansions.count, 0),
          tags: metrics.reduce((sum, metric) => sum + metric.mentions, 0),
          wordsBellsWrote: metrics.reduce((sum, metric) => sum + metric.inserts.words, 0),
          totalWords: metrics.reduce((sum, metric) => sum + metric.total.words, 0),
          sessionMinutes: encounter.duration ? Math.round(Duration.toLuxon(encounter.duration).as("minutes")) : 0,
          sessionToSignMinutes,
          sessionToSignLateMinutes,
          createdTime: Instant.toDate(note.created).toISOString(),
          dialogue: createRef(encounter.dialogues[0]),
          transcripts: metrics.reduce((sum, metric) => sum + metric.transcripts.count, 0),
          wordsFromTranscript: metrics.reduce((sum, metric) => sum + metric.transcripts.words, 0),
        },
        patientId: note.subject.id,
      });
    }

    // Log ignored suggestions that the user did not accept or dismiss
    suggestions.state.suggestions.forEach(section => {
      section.suggestions.filter(s => !s.suggestion.choice).forEach(token => {
        const { suggestion } = token;

        const replacement = "replacements" in suggestion ? suggestion.replacements.join(" | ") : undefined;
        tracking.track("Quill - Ignored Suggestion", {
          context: token.context,
          highlighted: token.target,
          noteId: note.id,
          rightPaneOpen: suggestions.state.rightPaneOpen,
          source: suggestion.source,
          action: suggestion.action,
          category: suggestion.category,
          description: suggestion.description,
          replacement,
          rule: token.ruleRef,
        });

        ingester.event("ISuggestionOutcomeV2", {
          payload: {
            action: "Ignored",
            source: suggestion.source === "nlp" ? "Clinical" : "Language",
            rule: parseRuleIdRef(suggestion.reference),
            note: createRef(note),
            context: token.context,
            highlighted: token.target,
            replacement,
            instructions: suggestion.description,
          },
          patientId: note.subject.resourceType === "Patient" ? note.subject.id : undefined,
        });
      });
    });

    // If part of group note, consider finalizing it now
    const multiNote = note.partOf ? await store.notes.expand(note.partOf) : null;
    if (isMultiNote(multiNote)) {
      await finalizeGroupNoteIfNeeded(multiNote);
    }

    // If part of care plan session, consider finalizing it now
    const carePlanSession = carePlanOutcome?.partOf ? await store.carePlanSessions.expand(carePlanOutcome.partOf) : null;
    if (carePlanSession) {
      await finalizeCarePlanSessionIfNeeded(carePlanSession);
    }
  }

  async function deleteCarePlanOutcome(carePlanOutcome: CarePlanOutcome) {
    const derivedNotes = await apollo.notes.feed({
      filters: [{
        derivedFrom: { in: [carePlanOutcome.id] },
        subject: { in: [carePlanOutcome.patient.id] },
      }],
      crossPartition: true,
    }).all();

    for (const note of derivedNotes) {
      await deleteNoteInternal(note as PatientNote);
    }

    await deleteCarePlanOutcomeInternal(carePlanOutcome);

    handleSigned();
  }

  async function deleteNote(note: PatientNote) {
    await deleteNoteInternal(note);

    const carePlanOutcome = note.derivedFrom ? await store.carePlanOutcomes.expand(note.derivedFrom) : null;
    if (carePlanOutcome && carePlanOutcome.status !== "Final") {
      await deleteCarePlanOutcomeInternal(carePlanOutcome);
    }
  }

  async function deleteEncounter(encounterRef: Reference<Encounter>) {
    try {
      const encounter = await store.encounters.expand(encounterRef);
      await clearDialogues(encounter);
    } catch {
      // Skip error
    }

    await store.encounters.deleteByIdAsync(encounterRef.partition, encounterRef.id);
  }

  async function clearDialogues(encounter: Encounter) {
    if (encounter.dialogues.length > 0) {
      const dialogues = await store.dialogues.expand(encounter.dialogues);
      for (const dialogue of dialogues) {
        if (!dialogue.meta?.isDeleted) {
          await deleteDialogue(dialogue);
        }
      }
    }
  }

  async function deleteDialogue(dialogue: Dialogue) {
    if (dialogue.audio) {
      await apollo.media.purgeContent(dialogue.audio.partition, dialogue.audio.id);
      await apollo.media.deleteContent(dialogue.audio.partition, dialogue.audio.id);
      store.media.deleteById(dialogue.audio.partition, dialogue.audio.id);
    }
    store.dialogues.deleteById(dialogue.partition, dialogue.id);
  }

  async function deleteNoteInternal(note: PatientNote) {
    const multiNote = note.partOf ? await store.notes.expand(note.partOf) : null;
    if (multiNote && isMultiNote(multiNote)) {
      // Must await, because we perform live counts later
      await store.notes.deleteByIdAsync(note.partition, note.id);
      await store.encounters.deleteByIdAsync(note.encounter.partition, note.encounter.id);
      await finalizeGroupNoteIfNeeded(multiNote);
    } else {
      store.notes.deleteById(note.partition, note.id);
      await deleteEncounter(note.encounter);
    }

    store.scribbles.purgeCache();
  }

  async function deleteCarePlanOutcomeInternal(carePlanOutcome: CarePlanOutcome) {
    const carePlanSession = carePlanOutcome.partOf ? await store.carePlanSessions.expand(carePlanOutcome.partOf) : null;
    if (carePlanSession) {
      // Must await, because we perform live counts later
      await store.carePlanOutcomes.deleteByIdAsync(carePlanOutcome.partition, carePlanOutcome.id);
      await finalizeCarePlanSessionIfNeeded(carePlanSession);
    } else {
      store.carePlanOutcomes.deleteById(carePlanOutcome.partition, carePlanOutcome.id);
    }
  }

  async function finalizeGroupNoteIfNeeded(note: MultiNote) {
    const pendingPatientNotes = await apollo.encounters.queryCount({
      filters: [{
        documentationStatus: {
          matches: "InProgress",
        },
        partOf: { in: [note.encounter.id] },
      }],
      crossPartition: true,
    });
    const signedPatientNotes = await apollo.encounters.queryCount({
      filters: [{
        documentationStatus: {
          matches: "Complete",
        },
        partOf: { in: [note.encounter.id] },
      }],
      crossPartition: true,
    });

    if (pendingPatientNotes === 0) {
      // Group Note had some signed notes, so we must sign the group note now
      if (signedPatientNotes > 0) {
        await signNote(note);
      } else {
        // Group note had no signed notes, so just delete it
        store.notes.deleteById(note.partition, note.id);
        store.encounters.deleteById(note.partition, note.encounter.id);
      }
    }
  }

  async function finalizeCarePlanSessionIfNeeded(carePlanSession: CarePlanSession) {
    const outcomes = await apollo.carePlanOutcomes.feed({
      filters: [{
        partOf: { in: [carePlanSession.id] },
      }],
      crossPartition: true,
    }).all();

    const pendingNotes = await apollo.notes.queryCount({
      filters: [{
        derivedFrom: {
          in: outcomes.map(o => o.id),
        },
        status: {
          in: ["Preliminary"],
        },
      }],
      crossPartition: true,
    });

    // If there are pending notes, don't finalize the session
    if (pendingNotes === 0) {
      // Session completed outcomes, so we must finalize it now
      if (outcomes.every(o => o.status === "Final")) {
        carePlanSession.status = "Final";
        store.carePlanSessions.upsert(carePlanSession);
      } else if (outcomes.length === 0) {
        // Session had no completed outcomes, so just delete it
        store.carePlanSessions.deleteById(carePlanSession.partition, carePlanSession.id);
      }
    }
  }
}

function shouldCreateAppointment(note: Note, createAppointment: boolean) {
  return createAppointment && note.sections.some(s => s.format === NoteSectionFormat.SessionTime) && !note.encounter.resource?.appointment;
}
