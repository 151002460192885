import type { ComposeEditor, ExpansionBlotData, InsertBlotData, QuillEditor, SuggestionBlotData } from "@remhealth/compose";

export interface ComposeUsageMetrics {
  mentions: number;
  choicePlaceholders: number;
  inserts: BlotMetric;
  expandOnceExpansions: BlotMetric;
  expansions: BlotMetric;
  snippets: BlotMetric;
  transcripts: BlotMetric;
  suggestions: {
    lang: QuillSuggestionMetrics;
    nlp: QuillSuggestionMetrics;
  };
  total: {
    words: number;
    characters: number;
  };
}

export interface QuillSuggestionMetrics {
  /** All suggestions, including those already accepted or ignored/dismissed. */
  total: BlotMetric;
  /** Suggestions user accepted. */
  accepted: BlotMetric;
  /** Suggestions user chose Ignore (dismissed). */
  ignored: BlotMetric;
  /** Suggestions user has still not made a decision on. */
  available: BlotMetric;
}

export interface BlotMetric {
  /** Number of times this blot occurred. */
  count: number;
  /** Total number of words found in this kind of blot. */
  words: number;
  /** Total number of characters found in this kind of blot. */
  characters: number;
}

export function computeUsageMetrics(compose: ComposeEditor): ComposeUsageMetrics {
  const content = (compose as QuillEditor).getContents();

  const metrics: ComposeUsageMetrics = {
    mentions: 0,
    choicePlaceholders: 0,
    inserts: { characters: 0, words: 0, count: 0 },
    expandOnceExpansions: { characters: 0, words: 0, count: 0 },
    snippets: { characters: 0, words: 0, count: 0 },
    expansions: { characters: 0, words: 0, count: 0 },
    transcripts: { characters: 0, words: 0, count: 0 },
    suggestions: {
      lang: {
        total: { characters: 0, words: 0, count: 0 },
        accepted: { characters: 0, words: 0, count: 0 },
        ignored: { characters: 0, words: 0, count: 0 },
        available: { characters: 0, words: 0, count: 0 },
      },
      nlp: {
        total: { characters: 0, words: 0, count: 0 },
        accepted: { characters: 0, words: 0, count: 0 },
        ignored: { characters: 0, words: 0, count: 0 },
        available: { characters: 0, words: 0, count: 0 },
      },
    },
    total: { characters: 0, words: 0 },
  };

  // Keep track of adjacent rh-ins, because sometimes formatting or tips can split up the same one
  let previousInsertBlot: InsertBlotData | undefined;

  content.forEach(op => {
    if (typeof op.insert === "string") {
      const { words, characters } = getBlotMetrics(op.insert);
      metrics.total.characters += characters;
      metrics.total.words += words;

      // Insert blots
      if (op.attributes?.insert) {
        if (!previousInsertBlot || !isSameInsertBlot(previousInsertBlot, op.attributes.insert)) {
          appendBlotMetrics(metrics.inserts, words, characters);

          const data: InsertBlotData = op.attributes.insert;
          switch (data.source) {
            case "expansion": {
              appendBlotMetrics(metrics.expansions, words, characters);
              break;
            }
            case "snippet": {
              appendBlotMetrics(metrics.snippets, words, characters);
              break;
            }
            case "transcript":
            case "transcript-summary": {
              appendBlotMetrics(metrics.transcripts, words, characters);
              break;
            }
          }
        }

        previousInsertBlot = op.attributes.insert;
      // New lines are never formatted, so don't reset insert blot info when encountering them
      } else if (op.insert !== "\n") {
        previousInsertBlot = undefined;
      }

      // Suggestion blots
      if (op.attributes?.tip) {
        const data = op.attributes.tip as SuggestionBlotData;
        const source = data.source === "nlp" ? metrics.suggestions.nlp : metrics.suggestions.lang;
        appendBlotMetrics(source.total, words, characters);

        switch (data.choice) {
          case "accepted": {
            appendBlotMetrics(source.accepted, words, characters);
            break;
          }
          case "ignored": {
            appendBlotMetrics(source.ignored, words, characters);
            break;
          }
          default: {
            appendBlotMetrics(source.available, words, characters);
            break;
          }
        }
      }
    }

    // Expansion blots
    if (op.insert && typeof op.insert !== "string" && "expansion" in op.insert) {
      const data = op.insert.expansion as ExpansionBlotData;
      const { words, characters } = getBlotMetrics(data.display);
      appendBlotMetrics(metrics.expandOnceExpansions, words, characters);
      appendBlotMetrics(metrics.expansions, words, characters);
    }

    // If formatted as mention blot
    if (op.insert && typeof op.insert !== "string" && "mention" in op.insert) {
      metrics.mentions++;
    }

    if (op.insert && typeof op.insert !== "string" && "choice" in op.insert) {
      metrics.choicePlaceholders++;
    }
  });

  return metrics;
}

function isSameInsertBlot(left: InsertBlotData, right: InsertBlotData): boolean {
  return left.source === right.source && left.reference === right.reference;
}

function getBlotMetrics(content: string): { words: number; characters: number } {
  const characters = content.length;
  const words = content.split(" ").filter(w => !!w.trim()).length;
  return { words, characters };
}

function appendBlotMetrics(blotMetrics: BlotMetric, wordCount: number, characterCount: number) {
  blotMetrics.characters += characterCount;
  blotMetrics.words += wordCount;
  blotMetrics.count++;
}
