import { useCallback, useEffect, useMemo, useState } from "react";
import { type Encounter, type HealthcareService, LocalDate, type LocationRole, type Reference } from "@remhealth/apollo";
import { HealthcareServiceFilterOptions, createHealthcareServicesFilters, healthcareServicesFilter, useEhr, useLabeling } from "@remhealth/core";
import { useApollo, useProductFlag, useReleaseCheck } from "@remhealth/host";
import type { PagedResults } from "@remhealth/mastodon";
import type { PagingResult } from "@remhealth/ui";
import { Text } from "~/text";

export interface ServiceTypeQueryOptions {
  allowEhr?: boolean;
  patientId?: string;
  episodeOfCareId?: string;
  programId?: string;
  locationId?: string;
  locationRole?: LocationRole;
  visitDate?: LocalDate;
  limitToServiceTypeIds?: string[];
  excludedServiceTypeIds?: string[];
  missedVisitsOnly?: boolean;
  relatesToAppointment?: boolean;
  recentEncounters?: Encounter[];
}

export function useServiceTypeQuery(options: ServiceTypeQueryOptions | undefined, postFilter?: (item: Reference<HealthcareService>) => boolean) {
  const {
    patientId,
    episodeOfCareId,
    programId,
    locationId,
    locationRole,
    visitDate,
    recentEncounters = [],
    limitToServiceTypeIds,
    excludedServiceTypeIds,
    relatesToAppointment = false,
    missedVisitsOnly = false,
  } = options ?? {};

  const apollo = useApollo();
  const ehrService = useEhr();
  const labels = useLabeling();
  const liveCall = useProductFlag("ServiceTypeLiveCall");
  const liveCallByPatient = useProductFlag("ServiceTypeByPatientCall");
  const multiServiceFilter = useReleaseCheck("MultiServiceFilter");

  const [recentServiceTypes, setRecentServiceTypes] = useState<Reference<HealthcareService>[]>([]);

  const allowEhr = liveCall && options?.allowEhr;

  const filterOptions = useMemo<HealthcareServiceFilterOptions>(() => ({
    missedVisitsOnly,
    allowedLocations: locationRole ? [locationRole] : undefined,
    ids: limitToServiceTypeIds,
    notIds: excludedServiceTypeIds,
    effectiveDate: visitDate,
  }), [missedVisitsOnly, locationRole, limitToServiceTypeIds?.join("|"), excludedServiceTypeIds?.join("|"), visitDate]);

  const filter = useCallback(serviceTypeFilter, [postFilter, filterOptions]);

  useEffect(() => {
    if (!allowEhr) {
      setRecentServiceTypes(recentEncounters.flatMap(e => e.serviceType ?? []));
    }
  }, [allowEhr, recentEncounters.length]);

  return {
    allowEhr,
    queryable: allowEhr ? ehrQueryable : queryable,
    ehrLoad,
    getMissingDependency,
  };

  function getMissingDependency(): string | undefined {
    if (allowEhr) {
      if (!programId) {
        return `${Text.Program} required to search for ${labels.serviceTypes}.`;
      }

      if (!visitDate) {
        return `${Text.SessionDate(labels)} required to search for ${labels.serviceTypes}.`;
      }

      if (liveCallByPatient) {
        if (!patientId) {
          return `${labels.Patient} required to search for ${labels.serviceTypes}.`;
        }

        if (!episodeOfCareId) {
          return `${labels.Enrollment} required to search for ${labels.serviceTypes}.`;
        }
      } else if (!locationId) {
        return `${labels.Location} required to search for ${labels.serviceTypes}.`;
      }
    }

    return undefined;
  }

  async function queryable(query: string, abort: AbortSignal): Promise<PagingResult<HealthcareService>> {
    if (!query) {
      const services = await apollo.healthcareServices.expand(recentServiceTypes);
      const filtered = services
        .filter(filter)
        // Exclude deleted and historical services since this is a new note being created and shouldn't refererence old values
        .filter(s => !s.meta?.isDeleted && !s.meta?.isHistorical);

      return { items: filtered, hasMore: true };
    }

    if (limitToServiceTypeIds && limitToServiceTypeIds.length === 0) {
      return { items: [], hasMore: false };
    }

    const filterSets = createHealthcareServicesFilters({
      query,
      ...filterOptions,
    });

    const response = await apollo.healthcareServices.query({
      filters: filterSets,
      orderBy: {
        field: "Display",
        direction: "Ascending",
      },
      feedOptions: {
        maxItemCount: 10,
      },
      abort,
    });
    return { items: response.results, hasMore: !!response.continuationToken };
  }

  async function ehrQueryable(query: string, abort: AbortSignal): Promise<PagingResult<HealthcareService>> {
    if (!query) {
      return { hasMore: false, items: [] };
    }

    const response = await ehrLoad(query, 10, undefined, abort);
    return { hasMore: !!response.continuationToken, items: response.results };
  }

  async function ehrLoad(query: string, limit: number, continuationToken: string | undefined, abort: AbortSignal): Promise<PagedResults<HealthcareService>> {
    if (limitToServiceTypeIds && limitToServiceTypeIds.length === 0) {
      return { results: [] };
    }

    // Load as much as we can in the hopes that we can use the filter
    if (!continuationToken && multiServiceFilter) {
      const page = await ehrLoadPage(query, 100, undefined, abort);

      if (!page.continuationToken) {
        return { results: page.results.filter(filter) };
      }

      return page;
    }

    return await ehrLoadPage(query, limit, continuationToken, abort);
  }

  async function ehrLoadPage(query: string, limit: number, continuationToken: string | undefined, abort: AbortSignal): Promise<PagedResults<HealthcareService>> {
    const feedOptions = { limit, continuationToken };

    const purpose = patientId ? relatesToAppointment ? "Appointment" : "Patient" : "Group";

    if (liveCallByPatient) {
      if (!episodeOfCareId || !patientId || !programId || !visitDate) {
        return { continuationToken: undefined, results: [] };
      }

      return await ehrService.searchServiceTypesByPatient(query, feedOptions, patientId, episodeOfCareId, programId, visitDate, purpose, false, abort);
    }

    if (!programId || !locationId || !visitDate) {
      return { continuationToken: undefined, results: [] };
    }

    return await ehrService.searchServiceTypesByProgram(query, feedOptions, programId, locationId, visitDate, purpose, abort);
  }

  function serviceTypeFilter(item: HealthcareService): boolean {
    if (postFilter && !postFilter(item)) {
      return false;
    }

    return healthcareServicesFilter(item, filterOptions);
  }
}
